export const tools = [
  {
    slug: 'typescript',
    text: 'typescript',
    background: '3178C6',
    color: 'white',
  },
  {
    slug: 'express',
    text: 'express.js',
    background: '000000',
    color: 'white',
  },
  {
    slug: 'react',
    text: 'react',
    background: '61DAFB',
    color: 'black',
  },
  {
    slug: 'redux',
    text: 'redux',
    background: '764ABC',
    color: 'white',
  },
  {
    slug: 'styled-components',
    text: 'styled components',
    background: 'DB7093',
    color: 'white',
  },
  {
    slug: 'docker',
    text: 'docker',
    background: '2496ED',
    color: 'white',
  },
  {
    slug: 'mysql',
    text: 'mysql',
    background: '4479A1',
    color: 'white',
  },
  {
    slug: 'nodedotjs',
    text: 'node.js',
    background: '339933',
    color: 'white',
  },
  {
    slug: 'javascript',
    text: 'javascript es6+',
    background: 'F7DF1E',
    color: 'black',
  },
  {
    slug: 'html5',
    text: 'html5',
    background: 'E34F26',
    color: 'white',
  },
  {
    slug: 'css3',
    text: 'css3',
    background: '1572B6',
    color: 'white',
  },
  {
    slug: 'git',
    text: 'git',
    background: 'F05032',
    color: 'white',
  },
  {
    slug: 'github',
    text: 'github',
    background: '181717',
    color: 'white',
  },
  {
    slug: 'jest',
    text: 'jest',
    background: 'F05032',
    color: 'white',
  },
  {
    slug: 'mocha',
    text: 'mocha',
    background: '8D6748',
    color: 'white',
  },
  {
    slug: 'testing-library',
    text: 'Testing Library',
    background: 'E33332',
    color: 'white',
  },
];